import type { StoreActionApi } from 'react-sweet-state';
import isEqual from 'lodash/isEqual';

import { createStore, createHook, createActionsHook } from 'react-sweet-state';

interface EditorAnnotationsState {
	annotations: string[];
}
const initialState: EditorAnnotationsState = { annotations: [] };

const actions = {
	setEditorAnnotations:
		(newAnnotations: string[]) =>
		({ getState, setState }: StoreActionApi<EditorAnnotationsState>) => {
			const { annotations } = getState();
			if (!isEqual(newAnnotations, annotations)) {
				setState({ annotations: newAnnotations });
			}
		},
};

const EditorAnnotationsStore = createStore({
	initialState,
	actions,
	name: 'EditorAnnotationsStore',
});

export const useEditorAnnotations = createHook(EditorAnnotationsStore);

export const useEditorAnnotationsActions = createActionsHook(EditorAnnotationsStore);
