import {
	createStore,
	createHook,
	createSubscriber,
	createActionsHook,
	createStateHook,
} from 'react-sweet-state';

import { findUnresolvedCommentIndex } from './findUnresolvedCommentIndex';

type InlineCommentsState = {
	unresolvedInlineComments: string[];
};

export type InlineCommentsContextType = {
	updateUnresolvedInlineComment: (unresolvedInlineComments: string[]) => void;
};

const initialState: InlineCommentsState = {
	unresolvedInlineComments: [],
};

const actions = {
	updateUnresolvedInlineComment:
		(unresolvedInlineComments: string[]) =>
		({ setState }: any) => {
			setState({ unresolvedInlineComments });
		},
	addUnresolvedInlineComment:
		(unresolvedAnnotationId: string | null, pageModes: string) =>
		({ setState, getState }: any) => {
			if (!unresolvedAnnotationId) {
				return;
			}

			const { unresolvedInlineComments } = getState();

			setState({
				unresolvedInlineComments: findUnresolvedCommentIndex(
					unresolvedAnnotationId,
					unresolvedInlineComments,
					pageModes,
				),
			});
		},
	removeUnresolvedInlineComment:
		(unresolvedAnnotationId: string | null) =>
		({ setState, getState }: any) => {
			if (!unresolvedAnnotationId) {
				return;
			}

			const { unresolvedInlineComments } = getState();
			const updatedUnresolvedInlineComments = [...unresolvedInlineComments];

			const unresolvedAnnotationIdx =
				updatedUnresolvedInlineComments.indexOf(unresolvedAnnotationId);

			// Remove the passed in comment if it exists and reset the state
			if (unresolvedAnnotationIdx > -1) {
				updatedUnresolvedInlineComments.splice(unresolvedAnnotationIdx, 1);

				setState({
					unresolvedInlineComments: updatedUnresolvedInlineComments,
				});
			}
		},
};

const InlineCommentsStore = createStore({
	initialState,
	actions,
	name: 'InlineCommentsStore',
});

export const useInlineComments = createHook(InlineCommentsStore);

export const useInlineCommentsActions = createActionsHook(InlineCommentsStore);
export const useInlineCommentsState = createStateHook(InlineCommentsStore);

export const InlineCommentsSubscriber = createSubscriber(InlineCommentsStore);
