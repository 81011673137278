import gql from 'graphql-tag';

export const authorFragment = gql`
	fragment authorFragment on AtlassianUser {
		displayName
		photos {
			isPrimary
			value
		}
		id
	}
`;

export const commentDataFragment = gql`
	fragment commentDataFragment on Comment {
		id
		ancestors {
			id
		}
		author {
			type
			displayName
			profilePicture {
				path
			}
			... on KnownUser {
				accountId
				permissionType
			}
			... on User {
				accountId
				permissionType
			}
		}
		body(representation: ATLAS_DOC_FORMAT) {
			value
		}
		contentStatus
		createdAt {
			value
		}
		createdAtNonLocalized
		links {
			webui
		}
		location {
			type
			... on InlineComment {
				inlineMarkerRef
				inlineText
				inlineResolveProperties {
					resolvedByDangling
					resolvedTime
					resolvedFriendlyDate
					resolvedUser
					resolved
				}
			}
			... on FooterComment {
				commentResolveProperties {
					resolvedTime
					resolvedFriendlyDate
					resolvedUser
					resolved
				}
			}
		}
		parentId
		permissions {
			isEditable
			isRemovable
			isResolvable
		}
		version {
			friendlyWhen
			number
			when
			by {
				type
				displayName
				... on KnownUser {
					accountId
				}
				... on User {
					accountId
				}
			}
		}
	}
`;

export const contentCommentListFragment = gql`
	fragment contentCommentListFragment on PaginatedContentList {
		count
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

export const commentListFragment = gql`
	fragment commentListFragment on PaginatedCommentList {
		totalCount
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

export const contentFragmentWithHistory = gql`
	fragment contentFragmentWithHistory on Content {
		id
		operations {
			operation
			targetType
		}
		space {
			id
			homepageId
		}
		type
		history {
			createdBy {
				... on KnownUser {
					accountId
				}
				... on User {
					accountId
				}
			}
		}
	}
`;
