/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';

import { markErrorAsHandled } from '@confluence/graphql';
import type { GraphQLContentStatus } from '@confluence/inline-comments-queries';
import { useUnreadComments } from '@confluence/unread-comments';
import { useSessionData } from '@confluence/session-data';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useEditorAnnotations } from '@confluence/inline-comments-hooks';
import {
	ActiveCommentsQuery,
	type ActiveCommentsQueryType,
	type ActiveCommentsQueryVariables,
} from '@confluence/comments-panel-queries';

import { useCommentsData } from '../useCommentsData';
import { updateCommentsDataState } from '../helper/commentsDataHelper';

export const useActiveCommentsQuery = ({
	pageId,
	skip = false,
}: {
	pageId: string;
	skip?: boolean;
}) => {
	// The query loading was not sticking around long enough or triggering at the right times
	// so I created a loading state that will set after the query is successful/fails
	const [loading, setLoading] = useState(true);
	const [{ commentsDataMap }, { addNewCommentThreads, setCommentsDataMap }] = useCommentsData();

	const [{ readCommentsListState }] = useUnreadComments();

	const [{ annotations }] = useEditorAnnotations();
	const pageMode = useGetPageMode();
	const { userId: currentUserId } = useSessionData();

	const annotationsInEditorDoc = useMemo(() => {
		return new Set(annotations);
	}, [annotations]);

	// Determine if the query should be skipped
	const skipQuery = !pageId || skip;

	const hasAlreadyFetchedInitialData =
		Object.keys(commentsDataMap.inline).length > 0 ||
		Object.keys(commentsDataMap.general).length > 0;

	// Execute the query
	const {
		data,
		loading: queryLoading,
		error,
		refetch,
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	} = useQuery<ActiveCommentsQueryType, ActiveCommentsQueryVariables>(ActiveCommentsQuery, {
		variables: {
			pageId,
			contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
		},
		// Skip is not respected after the initial call: https://github.com/apollographql/apollo-client/issues/6190#issuecomment-649933628
		fetchPolicy: skipQuery ? 'cache-only' : 'cache-and-network', // We want to utilize the cache as much as possible, but keep the list up to date if it changes
		skip: skipQuery,
		onCompleted: (result) => {
			updateCommentsDataState({
				data: result,
				readCommentsListState,
				updateData: hasAlreadyFetchedInitialData ? addNewCommentThreads : setCommentsDataMap,
				annotationsInEditorDoc,
				pageMode,
				currentUserId: currentUserId ?? '',
				isOpen: true,
			});
			setLoading(false);
		},
		onError: (err) => {
			// If the error is related to reactionsSummary returning null, just mark it handled
			// or if the error is related to cc-pages not being reachable, just mark it handled
			if (err.message.search(/reactions/i) !== -1 || err.message.search(/cc-pages/i) !== -1) {
				markErrorAsHandled(err);
			}

			setLoading(false);
		},
	});

	useEffect(() => {
		if (queryLoading) {
			setLoading(true);
		}
	}, [queryLoading]);

	return {
		loading: !skipQuery ? loading : false, // If we skip the query make sure loading is false
		data,
		error,
		refetch,
	};
};
