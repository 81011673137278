import type { DataProxy } from 'apollo-cache';
import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';
import isEqual from 'lodash/isEqual';

import {
	ActiveCommentsQuery,
	type ActiveCommentsQueryType,
	type ActiveCommentsQueryVariables,
	type ActiveCommentsPanelNode,
	type ActiveCommentsPanelReply,
	type GraphQLContentStatus,
	type CommentInlineLocation,
} from '@confluence/comments-panel-queries';
import type {
	TopLevelComment as BaseComment,
	CommentReply as BaseReply,
	ReactionsSummary,
	ResolvedProperties,
} from '@confluence/inline-comments-queries';
import { getLogger } from '@confluence/logger';

const logger = getLogger('comments-data');

export enum CommentType {
	INLINE = 'inline',
	GENERAL = 'general',
}

export enum UnreadAction {
	READ,
	UNREAD,
}

export enum CommentActionType {
	CREATE_COMMENT = 'created',
	DELETE_COMMENT = 'deleted',
	RESOLVE_COMMENT_THREAD = 'resolved',
	REOPEN_COMMENT_THREAD = 'reopened',
	REATTACH_COMMENT = 'reattach',
}

export interface AnnotationStatus {
	threadKey: string;
	isLoaded: boolean;
}

export interface ReplyData extends BaseReply {
	isUnread: boolean;
	wasRemovedByAnotherUser:
		| false
		| CommentActionType.DELETE_COMMENT
		| CommentActionType.RESOLVE_COMMENT_THREAD;
}

export interface CommentData extends BaseComment {
	isUnread: boolean;
	isOpen: boolean; // unresolved comment
	type: CommentType;
	wasRemovedByAnotherUser:
		| false
		| CommentActionType.DELETE_COMMENT
		| CommentActionType.RESOLVE_COMMENT_THREAD;
	replies: ReplyData[];
}

type ThreadKeyCommentIdMap = Record<string, Set<string>>;
type CommentUnreadUpdateMap = {
	inline: ThreadKeyCommentIdMap;
	general: ThreadKeyCommentIdMap;
};

type DataMap = Record<string, CommentData>;
export type CommentsDataMap = {
	inline: DataMap; // source of truth that maps annotation to a comment thread
	general: DataMap;
};

// Maps annotation position on page to threadKey, used to show the removed comment thread in the view
type RemovedThreadsMap = Record<number, string>;
export type RemovedThreadsCommentTypeMap = {
	inline: RemovedThreadsMap;
	general: RemovedThreadsMap;
};

export type CommentsDataState = {
	orderedActiveAnnotationIdList: AnnotationStatus[]; // ordered list of annotations on the page
	commentsDataMap: CommentsDataMap;
	removedThreadsMap: RemovedThreadsCommentTypeMap;
	totalCommentCount: number;
};

export const getInitialState = (): CommentsDataState => ({
	orderedActiveAnnotationIdList: [],
	commentsDataMap: {
		inline: {},
		general: {},
	},
	removedThreadsMap: {
		inline: {},
		general: {},
	},
	totalCommentCount: 0,
});

export const actions = {
	setInitialCommentCountOnLoad:
		(count: number) =>
		({ setState }: StoreActionApi<CommentsDataState>) => {
			setState({ totalCommentCount: count });
		},
	setCommentsDataMap:
		(newCommentsDataMap: CommentsDataMap) =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { orderedActiveAnnotationIdList } = getState();

			const parentCommentThreadKeys = new Set(Object.keys(newCommentsDataMap.inline));

			// Update the loaded status of annotations based on newCommentsDataMap
			const updatedAnnotationList = orderedActiveAnnotationIdList.map((annotation) => ({
				...annotation,
				isLoaded: parentCommentThreadKeys.has(annotation.threadKey),
			}));

			setState({
				commentsDataMap: newCommentsDataMap,
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	setOrderedActiveAnnotationIdList:
		(newCommentsList: string[]) =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { orderedActiveAnnotationIdList } = getState();
			if (!newCommentsList || newCommentsList.length === 0) {
				// Only update state if the current list is not already empty
				if (orderedActiveAnnotationIdList.length > 0) {
					setState({ orderedActiveAnnotationIdList: [] });
				}
				return;
			}

			// Create a map of existing annotationId to their isLoaded states for quick lookup
			const existingAnnotationsMap = new Map(
				orderedActiveAnnotationIdList.map(({ threadKey, isLoaded }) => [threadKey, isLoaded]),
			);
			// Use a Set to ensure unique annotation IDs
			const uniqueCommentsSet = new Set(newCommentsList);
			// Map the unique comments set to the new state, preserving isLoaded where possible
			const updatedAnnotationList = Array.from(uniqueCommentsSet).map((threadKey) => ({
				threadKey,
				isLoaded: existingAnnotationsMap.get(threadKey) ?? false, // Preserve existing isLoaded or default to false
			}));

			if (!isEqual(orderedActiveAnnotationIdList, updatedAnnotationList)) {
				setState({
					orderedActiveAnnotationIdList: updatedAnnotationList,
				});
			}
		},
	updateReactionsSummary:
		(
			emojiId: string,
			actionType: 'add' | 'delete',
			commentId: string,
			commentType: CommentType,
			pageId?: string,
			threadKey?: string,
			cache?: DataProxy,
		) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const updateReactionsForComment = (
				comment: CommentData | ActiveCommentsPanelNode | ReplyData | ActiveCommentsPanelReply,
				emojiId: string,
				actionType: 'add' | 'delete',
			) => {
				const { reactionsSummary } = comment;

				if (!reactionsSummary) {
					return;
				}

				const reactionsAri = reactionsSummary?.ari || '';
				const reactionsContainerAri = reactionsSummary?.containerAri || '';
				const reactionsNodes = reactionsSummary?.reactionsSummaryForEmoji || [];
				let reactionsCount = reactionsSummary?.reactionsCount || 0;

				const updatedReactionsNodes = [...reactionsNodes];
				const updatedReactionsSummary = { ...reactionsSummary };

				if (actionType === 'add') {
					const reaction = updatedReactionsNodes.find((item) => item?.emojiId === emojiId);

					// If reaction exists, update its attributes
					if (reaction && !reaction.reacted) {
						reaction.count++;
						reaction.reacted = true;
					} else {
						// Otherwise, create a new reaction
						const newReaction = {
							emojiId,
							count: 1,
							reacted: true,
							id: `${reactionsContainerAri}|${reactionsAri}|${emojiId}`,
						};

						updatedReactionsNodes.push(newReaction);
					}

					++reactionsCount;
				} else if (actionType === 'delete') {
					const reactionIdx = updatedReactionsNodes.findIndex((item) => item?.emojiId === emojiId);

					// If reaction exists, update its attributes
					if (reactionIdx !== -1) {
						const reaction = updatedReactionsNodes[reactionIdx];

						// If we have more than one reaction, remove our user from it and decrement the count
						if (reaction && reaction.count > 1) {
							reaction.count--;
							reaction.reacted = false;
						} else {
							// Otherwise, remove the reaction entirely
							updatedReactionsNodes.splice(reactionIdx, 1);
						}

						--reactionsCount;
					}
				}

				updatedReactionsSummary.reactionsSummaryForEmoji = updatedReactionsNodes;
				updatedReactionsSummary.reactionsCount = reactionsCount;

				comment.reactionsSummary = updatedReactionsSummary as ReactionsSummary;
			};

			try {
				const queryVariables = {
					pageId: pageId!,
					contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
				};

				// Update the comments panel cache
				const cacheResult = cache?.readQuery<ActiveCommentsQueryType, ActiveCommentsQueryVariables>(
					{
						query: ActiveCommentsQuery,
						variables: queryVariables,
					},
				);

				if (cacheResult) {
					const updatedCacheResult = { ...cacheResult };

					const commentNodes = updatedCacheResult.comments?.nodes || [];
					const comment = commentNodes.find((node) =>
						commentType === CommentType.GENERAL
							? node?.id === threadKey
							: (node?.location as CommentInlineLocation)?.inlineMarkerRef === threadKey,
					);
					let reply: ReplyData | undefined;

					if (comment) {
						// If the comment IDs don't match, this is a reply, find it and use that
						if (comment.id !== commentId) {
							reply = (comment.replies as ReplyData[]).find((r) => r.id === commentId);

							// If we don't find the reply after that, just return
							if (!reply) {
								return;
							}
						}

						updateReactionsForComment(reply || comment, emojiId, actionType);

						cache?.writeQuery({
							query: ActiveCommentsQuery,
							variables: queryVariables,
							data: updatedCacheResult,
						});
					}
				}
			} catch (err) {
				logger.error`An error occurred when updating comments panel cache - ${err}`;
			}

			// Update the comment datasource
			const { commentsDataMap } = getState();
			const updatedMap = { ...commentsDataMap };

			if (!threadKey) {
				return;
			}

			const comment: CommentData | undefined = updatedMap[commentType][threadKey];
			let reply: ReplyData | undefined;

			// If the comment doesn't exist in the map, just return
			if (!comment) {
				return;
			}

			// If the comment IDs don't match, this is a reply, find it and use that
			if (comment.id !== commentId) {
				reply = comment.replies.find((r: ReplyData) => r.id === commentId);

				// If we don't find the reply after that, just return
				if (!reply) {
					return;
				}
			}

			updateReactionsForComment(reply || comment, emojiId, actionType);

			setState({
				commentsDataMap: updatedMap,
			});
		},
	updateUnreadStatus:
		(commentUnreadUpdateMap: CommentUnreadUpdateMap, action: UnreadAction) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap } = getState();

			const updatedMap = { ...commentsDataMap };

			const updateUnreadFn =
				(commentType: CommentType) =>
				([threadKey, commentIdsToMark]: [threadKey: string, commentIdsToMark: Set<string>]) => {
					const commentData = updatedMap[commentType][threadKey];
					if (commentData) {
						const isParentCommentUnread = commentIdsToMark.has(commentData.id);
						updatedMap[commentType][threadKey] = {
							...commentData,
							isUnread: isParentCommentUnread
								? action === UnreadAction.UNREAD
								: commentData.isUnread,
							replies: commentData.replies?.map((reply) => {
								// Update the reply's unread status if its ID is included in commentIdsToMark
								if (commentIdsToMark.has(reply.id)) {
									return {
										...reply,
										isUnread: action === UnreadAction.UNREAD,
									};
								}
								return reply;
							}),
							wasRemovedByAnotherUser: false,
						};
					}
				};

			// For each threadKey and their comment IDs, update the unread status in the inline commentsDataMap
			Object.entries(commentUnreadUpdateMap.inline).forEach(updateUnreadFn(CommentType.INLINE));

			// For each threadKey and their comment IDs, update the unread status in the general commentsDataMap
			Object.entries(commentUnreadUpdateMap.general).forEach(updateUnreadFn(CommentType.GENERAL));

			setState({ commentsDataMap: updatedMap });
		},
	addNewCommentThreads:
		(newCommentThreads: CommentsDataMap) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap, orderedActiveAnnotationIdList } = getState();

			const updatedCommentsDataMap = { ...commentsDataMap };

			// Add the comment threads for inline
			for (const threadKey in newCommentThreads.inline) {
				const newCommentThread = newCommentThreads.inline[threadKey];
				updatedCommentsDataMap.inline[threadKey] = newCommentThread;
			}

			// Add the comment threads for general
			for (const threadKey in newCommentThreads.general) {
				const newCommentThread = newCommentThreads.general[threadKey];
				updatedCommentsDataMap.general[threadKey] = newCommentThread;
			}

			// Update the loaded status of annotations
			const updatedAnnotationList = orderedActiveAnnotationIdList.map((annotation) => ({
				...annotation,
				isLoaded: Object.keys(newCommentThreads).includes(annotation.threadKey)
					? true
					: annotation.isLoaded,
			}));

			setState({
				commentsDataMap: updatedCommentsDataMap,
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	addReplyToCommentThread:
		(threadKey: string, reply: ReplyData, commentType: CommentType) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap } = getState();

			const updatedCommentsDataMap = { ...commentsDataMap };

			const parentCommentThread = updatedCommentsDataMap[commentType][threadKey];
			if (parentCommentThread) {
				// due to the nature of how we are sending multiple pubsub events are being sent for comment creation, we have to check for a duplicate before adding a reply
				const replyExists = parentCommentThread.replies?.some(
					(existingReply) => existingReply.id === reply.id,
				);
				if (!replyExists) {
					const updatedReplies = [...parentCommentThread.replies, reply];

					const updatedParentCommentThread = {
						...parentCommentThread,
						replies: updatedReplies,
					};

					updatedCommentsDataMap[commentType][threadKey] = updatedParentCommentThread;

					setState({
						commentsDataMap: updatedCommentsDataMap,
					});
				}
			}
		},
	getCurrentCommentsPanelState:
		() =>
		({ getState }: StoreActionApi<CommentsDataState>) => {
			return getState();
		},
	updateCommentBody:
		(commentId: string, threadKey: string, newBodyValue: string, commentType: CommentType) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			// Find the comment in the map
			const { commentsDataMap } = getState();

			// Make a copy of the map so if looks like a new object in shallow comparisons and also
			// avoids mutating the original map
			const updatedMap = { ...commentsDataMap };

			// The "parentComment" is either the comment itsef (if a top-level comment) or the parent of
			// the reply thread
			const parentComment = updatedMap[commentType][threadKey];
			if (parentComment) {
				// If the modified comment is a top-level comment, update the body value
				if (parentComment.id === commentId) {
					// Make a copy of the comment and update the body value, then put it back into the map
					const updatedComment = {
						...parentComment,
						body: { value: newBodyValue },
					};
					updatedMap[commentType][threadKey] = updatedComment;

					setState({ commentsDataMap: updatedMap });
				} else {
					// Else we didn't find the comment at the top-level, check the replies
					for (let replyIndex = 0; replyIndex < parentComment.replies.length; replyIndex++) {
						const reply = parentComment.replies[replyIndex];
						if (reply.id === commentId) {
							// Make a copy of the comment and update the reply's body value, then put it back into the map
							const updatedReply = {
								...reply,
								body: { value: newBodyValue },
							};
							// (A reminder that ... does a shallow copy, so we need to copy the replies array as well)
							const updatedComment = {
								...parentComment,
								replies: [...parentComment.replies],
							};
							updatedComment.replies[replyIndex] = updatedReply;
							updatedMap[commentType][threadKey] = updatedComment;

							setState({ commentsDataMap: updatedMap });
							break;
						}
					}
				}
			}
		},
	// keep track of removed annotations as well as update removed comments with wasRemovedByAnotherUser
	updateRemovedCommentIdsMap:
		({
			threadKey,
			commentId,
			commentActionType,
			isReply,
			commentType,
			resolvedProperties,
		}: {
			threadKey: string;
			commentId: string;
			commentActionType:
				| CommentActionType.DELETE_COMMENT
				| CommentActionType.RESOLVE_COMMENT_THREAD;
			isReply: boolean;
			commentType: CommentType;
			resolvedProperties?: {
				inlineResolveProperties: ResolvedProperties;
				inlineText: string;
			};
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap, orderedActiveAnnotationIdList, removedThreadsMap } = getState();
			const updatedCommentsDataMap = { ...commentsDataMap };
			const commentThread = updatedCommentsDataMap[commentType][threadKey];

			if (commentThread) {
				if (isReply) {
					const updatedCommentThreadReplies = commentThread.replies.map((r) => {
						if (r.id === commentId) {
							return {
								...r,
								wasRemovedByAnotherUser: commentActionType,
							};
						}
						return r;
					});

					updatedCommentsDataMap[commentType][threadKey] = {
						...commentThread,
						replies: updatedCommentThreadReplies,
					};
				} else {
					if (resolvedProperties) {
						updatedCommentsDataMap[commentType][threadKey] = {
							...commentThread,
							location: {
								type: 'INLINE',
								inlineMarkerRef: threadKey,
								inlineResolveProperties: resolvedProperties.inlineResolveProperties,
								inlineText: resolvedProperties.inlineText,
							},
							isOpen: false,
							wasRemovedByAnotherUser: commentActionType,
						};
					} else {
						updatedCommentsDataMap[commentType][threadKey] = {
							...commentThread,
							isOpen: false,
							wasRemovedByAnotherUser: commentActionType,
						};
					}
				}

				if (commentActionType === CommentActionType.RESOLVE_COMMENT_THREAD) {
					// mark those comments in the thread as read
					updatedCommentsDataMap[commentType][threadKey] = {
						...updatedCommentsDataMap[commentType][threadKey],
						isUnread: false,
						replies: commentThread.replies.map((r) => {
							return {
								...r,
								isUnread: false,
							};
						}),
					};
				}

				const updatedRemovedThreadsMap = { ...removedThreadsMap };
				let threadKeyId = -1;
				if (commentType === CommentType.INLINE) {
					// since orderedActiveAnnotationIdList will not include previously removed annotations, we need to add in annotations in removedThreadsMap
					// so that we can correctly find the index of the removed annotation
					const inlineThreadKeyList = [...orderedActiveAnnotationIdList.map((a) => a.threadKey)];

					Object.entries(updatedRemovedThreadsMap.inline).forEach(([index, key]) => {
						const position = parseInt(index, 10); // Ensure the index is treated as a number
						inlineThreadKeyList.splice(position, 0, key);
					});

					threadKeyId = inlineThreadKeyList.findIndex((key) => key === threadKey);
				} else {
					// General comments are ordered correctly in the map by timestamp
					const orderedGeneralThreadKeyList = Object.keys(commentsDataMap.general);

					threadKeyId = orderedGeneralThreadKeyList.findIndex((key) => key === threadKey);
				}

				if (threadKeyId !== -1) {
					updatedRemovedThreadsMap[commentType][threadKeyId] = threadKey;
				}

				setState({
					commentsDataMap: updatedCommentsDataMap,
					removedThreadsMap: updatedRemovedThreadsMap,
				});
			}
		},
	updateCommentCount:
		({
			threadKey,
			actionType,
			commentCountWithReplies,
			commentType,
		}: {
			threadKey: string;
			actionType: CommentActionType;
			commentCountWithReplies?: number;
			commentType: CommentType;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap, totalCommentCount } = getState();

			const commentDataMapCopy = { ...commentsDataMap };
			switch (actionType) {
				case CommentActionType.CREATE_COMMENT:
				case CommentActionType.REATTACH_COMMENT:
					setState({ totalCommentCount: totalCommentCount + 1 });
					break;
				case CommentActionType.DELETE_COMMENT:
					setState({ totalCommentCount: totalCommentCount - 1 });
					break;

				case CommentActionType.RESOLVE_COMMENT_THREAD:
					//TODO: For pubsub, we need to do it from editor
					const commentThreadCount = commentDataMapCopy[commentType][threadKey]
						? commentDataMapCopy[commentType][threadKey].replies.length + 1
						: commentCountWithReplies;
					setState({
						totalCommentCount: totalCommentCount - (commentThreadCount || 0),
					});
					break;
				case CommentActionType.REOPEN_COMMENT_THREAD:
					setState({
						totalCommentCount:
							totalCommentCount + (commentDataMapCopy[commentType][threadKey].replies.length + 1),
					});
					break;
				default:
					// Optionally handle unexpected events or do nothing
					break;
			}
		},
	handleRemovingComments:
		({
			threadKey,
			commentId,
			action,
			commentType,
		}: {
			threadKey: string;
			commentId?: string;
			action: CommentActionType;
			commentType: CommentType;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap } = getState();

			const updatedCommentsDataMap = { ...commentsDataMap };
			const parentCommentThread = updatedCommentsDataMap[commentType][threadKey];

			if (parentCommentThread) {
				if (
					action === CommentActionType.RESOLVE_COMMENT_THREAD &&
					parentCommentThread.id === commentId
				) {
					// If the action is to resolve the thread and the commentId matches the parent, remove the thread
					delete updatedCommentsDataMap[commentType][threadKey];
					setState({
						commentsDataMap: updatedCommentsDataMap,
					});
				} else if (action === CommentActionType.DELETE_COMMENT) {
					// If the action is to delete a reply, filter out the reply with the specified commentId
					const updatedReplies = parentCommentThread.replies.filter(
						(reply) => reply.id !== commentId,
					);

					updatedCommentsDataMap[commentType][threadKey] = {
						...parentCommentThread,
						replies: updatedReplies,
					};
					// Update the parent comment thread with the filtered replies
					setState({
						commentsDataMap: updatedCommentsDataMap,
					});
				}
			}
		},
	resolveParentComment:
		({ threadKey, commentType }: { threadKey: string; commentType: CommentType }) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap } = getState();

			const updatedCommentsDataMap = { ...commentsDataMap };
			const parentCommentThread = updatedCommentsDataMap[commentType][threadKey];

			if (parentCommentThread) {
				parentCommentThread.isOpen = false;

				setState({
					commentsDataMap: updatedCommentsDataMap,
				});
			}
		},
	deleteParentComment:
		({ threadKey, commentType }: { threadKey: string; commentType: CommentType }) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap } = getState();

			const updatedCommentsDataMap = { ...commentsDataMap };
			const parentCommentThread = updatedCommentsDataMap[commentType][threadKey];

			if (parentCommentThread) {
				delete updatedCommentsDataMap[commentType][threadKey];

				setState({
					commentsDataMap: updatedCommentsDataMap,
				});
			}
		},
	clearRemovedComments:
		() =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { commentsDataMap } = getState();

			const commentsDataMapCopy = { ...commentsDataMap };

			// filter out deleted / resolved comments
			const updatedInlineCommentsDataMap = Object.fromEntries(
				Object.entries(commentsDataMapCopy.inline)
					.filter(([_, commentData]) => !commentData.wasRemovedByAnotherUser)
					.map(([key, commentData]) => [
						key,
						{
							...commentData,
							replies: commentData.replies?.filter((reply) => !reply.wasRemovedByAnotherUser),
						},
					]),
			);

			const updatedGeneralCommentsDataMap = Object.fromEntries(
				Object.entries(commentsDataMapCopy.general)
					.filter(([_, commentData]) => !commentData.wasRemovedByAnotherUser)
					.map(([key, commentData]) => [
						key,
						{
							...commentData,
							replies: commentData.replies?.filter((reply) => !reply.wasRemovedByAnotherUser),
						},
					]),
			);

			setState({
				removedThreadsMap: { inline: {}, general: {} },
				commentsDataMap: {
					inline: updatedInlineCommentsDataMap,
					general: updatedGeneralCommentsDataMap,
				},
			});
		},
	clearCommentsData:
		() =>
		({ setState }: StoreActionApi<CommentsDataState>) => {
			setState(getInitialState());
		},
};

export const CommentsDataStore = createStore({
	initialState: getInitialState(),
	actions,
	name: 'CommentsDataStore',
});

export const useCommentsData = createHook(CommentsDataStore);
export const useCommentsDataActions = createActionsHook(CommentsDataStore);
export const useCommentsDataState = createStateHook(CommentsDataStore);
