import gql from 'graphql-tag';

export const inlineCommentFragment = gql`
	fragment inlineCommentFragment on Comment {
		id
		body(representation: ATLAS_DOC_FORMAT) {
			value
		}
		contentStatus
		createdAt {
			value
		}
		createdAtNonLocalized
		links {
			webui
			editui
		}
		location {
			type
			... on InlineComment {
				inlineMarkerRef
				inlineText
				inlineResolveProperties {
					resolved
					resolvedByDangling
					resolvedFriendlyDate
					resolvedTime
					resolvedUser
				}
			}
			... on FooterComment {
				commentResolveProperties {
					resolved
					resolvedFriendlyDate
					resolvedTime
					resolvedUser
				}
			}
		}
		parentId
		permissions {
			isEditable
			isRemovable
			isResolvable
		}
		version {
			when
			number
		}
		author {
			displayName
			profilePicture {
				path
			}
			permissionType
			... on KnownUser {
				accountId
			}
			... on User {
				accountId
			}
		}
	}
`;
