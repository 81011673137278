import gql from 'graphql-tag';

import {
	authorFragment,
	contentFragmentWithHistory,
	commentListFragment,
	commentDataFragment,
} from './CommentsSectionFragments.fragment';

export const CommentsSectionQuery = gql`
	query CommentsSectionQuery($offset: String, $first: Long, $contentId: ID!) @allowHigherTimeout {
		comments(
			pageId: $contentId
			type: FOOTER
			depth: ALL
			confluenceCommentFilter: { commentState: UNRESOLVED, commentType: FOOTER }
			first: $first
			after: $offset
		) {
			...commentListFragment
			edges {
				cursor
				node {
					...commentDataFragment
					reactionsSummary(pageId: $contentId, contentType: "page", childType: "comment") {
						ari
						containerAri
						reactionsCount
						reactionsSummaryForEmoji {
							id
							emojiId
							count
							reacted
						}
					}
				}
			}
		}
		content(id: $contentId) {
			nodes {
				subType
				...contentFragmentWithHistory
			}
		}
		user(current: true) {
			...authorFragment
		}
	}

	${authorFragment}
	${contentFragmentWithHistory}
	${commentListFragment}
	${commentDataFragment}
`;

export const CommentsSectionWithoutReactionsQuery = gql`
	query CommentsSectionWithoutReactionsQuery($offset: String, $first: Long, $contentId: ID!) {
		comments(
			pageId: $contentId
			type: FOOTER
			depth: ALL
			confluenceCommentFilter: { commentState: UNRESOLVED, commentType: FOOTER }
			first: $first
			after: $offset
		) {
			...commentListFragment
			edges {
				cursor
				node {
					...commentDataFragment
				}
			}
		}
		content(id: $contentId) {
			nodes {
				subType
				...contentFragmentWithHistory
			}
		}
		user(current: true) {
			...authorFragment
		}
	}

	${authorFragment}
	${contentFragmentWithHistory}
	${commentListFragment}
	${commentDataFragment}
`;
