import gql from 'graphql-tag';

export const commentFragmentWithoutReactions = gql`
	fragment commentFragmentWithoutReactions on Comment {
		id
		author {
			displayName
			profilePicture {
				path
			}
			permissionType
			... on KnownUser {
				accountId
			}
			... on User {
				accountId
			}
		}
		body(representation: ATLAS_DOC_FORMAT) {
			value
		}
		contentStatus
		createdAt {
			value
		}
		createdAtNonLocalized
		links {
			webui
			editui
		}
		location {
			type
			... on InlineComment {
				inlineMarkerRef
				inlineText
				inlineResolveProperties {
					resolvedByDangling
					resolvedTime
					resolvedFriendlyDate
					resolvedUser
					resolved
				}
			}
			... on FooterComment {
				commentResolveProperties {
					resolved
					resolvedFriendlyDate
					resolvedTime
					resolvedUser
				}
			}
		}
		parentId
		permissions {
			isEditable
			isRemovable
			isResolvable
		}
		version {
			when
			number
		}
	}
`;

export const commentFragment = gql`
	fragment commentFragment on Comment {
		...commentFragmentWithoutReactions
		reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
			ari
			containerAri
			reactionsCount
			reactionsSummaryForEmoji {
				id
				emojiId
				count
				reacted
			}
		}
	}

	${commentFragmentWithoutReactions}
`;
